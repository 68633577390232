//
import ProjectCard from '../ProjectCard/ProjectCard';

export enum ProjectCategories {
    TECHNIQUES = 'Nieuwe technieken',
    PROCESS = 'Procesinnovatie',
}

const projects = [
    {
        title: 'Verhaal van client',
        text: 'mevrouw de vries',
        categories: [ProjectCategories.PROCESS],
        id: 1,
    },
    {
        title: 'Ambient Assisted Living',
        text: 'Inzetten van smart sensors voor effectievere zorg.',
        categories: [ProjectCategories.TECHNIQUES],
        id: 2,
    },
    {
        title: 'Verhaal van famlie',
        text: 'Jan vertelt ',
        categories: [ProjectCategories.TECHNIQUES],
        id: 3,
    },
    {
        // TODO Dubbele machine learning blokken, weghalen of aanpassen
        title: 'Machine Learning',
        text: 'Regressie algoritmen voor het accurater inschatten van toekomstige vraag.',
        categories: [ProjectCategories.TECHNIQUES],
        id: 3,
    },
    {
        title: 'Hortizontal & vertical integration',
        // TODO ... invullen
        text: 'Optimaliseren van ... door in de hele keten data te verzamelen en te delen',
        categories: [ProjectCategories.PROCESS],
        id: 1,
    },
    {
        // TODO ....... invullen
        title: '.......',
        text: 'Verbeteren van afnamevoorspelling met exogene variabelen.',
        categories: [ProjectCategories.TECHNIQUES, ProjectCategories.PROCESS],
        id: 2,
    },
];

// eslint-disable-next-line
export default (props?: any, amount?: number, filter?: string) => {
    // Filter pages
    const filtered = filter ? projects.filter(p => p.categories.find(c => c === filter)) : projects;

    const limited = amount ? filtered.slice(0, amount) : filtered;

    // Group pages by 3
    const rows: any[] = [];
    for (let i = 0; i < Math.ceil(limited.length / 3); i++) {
        const ind = i * 3;
        rows.push(limited.slice(ind, ind + 3));
    }

    return (
        <div className='mt-5 mb-5 no-gutters justify-content-center column'>
            {rows.map(row => (
                <div className='row'>
                    {row.map(block => (
                        <div className='col-sm-12 col-md-6 col-lg-4'>
                            <ProjectCard
                                title={block.title}
                                text={block.text}
                                onClick={() => props.navigation.navigate('ProjectDetails', { id: block.id })}
                                imageNumber={block.id}
                                />
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};
