
import './styles.css';
import ServicesIcons from '../../Components/ServicesIcons/ServicesIcons';
import ImageTextBox from '../../Components/ImageTextBox/ImageTextBox';
import network from '../../assets/images/board.jpg';
import plants2 from '../../assets/images/plants2.jpg';
// import vegetables from '../../assets/images/vegetables.jpg';
import factory from '../../assets/images/factory.jpg';
import factory2 from '../../assets/images/factory2.jpg';
// import meten from '../../assets/images/meten.jpg';
import tv from '../../assets/images/tv.jpg';
import health from '../../assets/images/health.jpg';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from '../../Util/analytics/GoogleAnalytics.web';
import LOC from '../../Localization/Translations';
import logo from './../../assets/images/Mtek512x512.png';

const lang = LOC.getLanguage();


export default (props: any) => {
    GoogleAnalytics.getInstance().hit(props.navigation.state.key);

    const jsonld = {
        '@context': 'http://schema.org/',
        '@type': 'Webpage',
        'name': `${lang.name} - Services`,
        'image': logo,
        'description': `${lang.name} offers a wide selection of software development & strategy services. Prototyping, lean software development, Continuous development, innovation launch, digital strategy.`,
        'url': `https://www.${lang.domain}/services`,
    };

    return (
        <div>
            <Helmet>
                <title> {lang.name} - Development & Strategy Services  </title>
                <link rel='canonical' href={`https://www.${lang.domain}/services`} />

                <script className='structured-data-list' type='application/ld+json'>{JSON.stringify(jsonld)}</script>
            </Helmet>

            <div className='jumbotron jumbotron-fluid services-opening-jumbo orangeBackground' style={{ marginBottom: 0 }}>
                <div className='container d-table' style={{ height: '100%' }}>
                    <div className='text-center d-table-cell align-middle'>
                        <div className='jobHeader'>Markten</div>
                        <div className='jobSecondHeader'>
                        </div>
                    </div>
                </div>
            </div>
            <div className='full-gray'>
                <div className='container pt-5 pb-5'>
                    <ServicesIcons markets1={true} />
                </div>
            </div>
            <ImageTextBox
                smallImage
                imageLeft
                title={'...tek'}
                leadText=''
                text={'Samen met ...tek, computer vision ingezet om een kwaliteitscontrole te automatiseren. Met mobiele camera\'s worden producten geinspecteerd, en fouten eerder gedetecteerd. Hierdoor kan ...tek een lagere foutmarge realiseren. Sleuteltechnologie voor dit project is een AI model getrained met behulp van generatieve AI. Hierdoor is veel handwerk bespaart en kon het pilot project snel gelanceerd worden '}
                image={factory}
                buttonText={'Lees meer over toepassing Generatieve AI'}
                link={() => props.navigation.navigate('ProjectDetails', {id: 1})}
            />
            <ImageTextBox
                smallImage
                title={'Plantje.nl'}
                leadText=''
                text={'Voor plantje.nl hebben we hun geholpen met het beter inscahtten van de voorraad gebruikmakende van AI voor schattingen, hiermee kunnen meer externe factoren meegenomen worden en automatisch op significatie ingeschat worden. Na deze implementie kon er goed ingekocht worden op basis van verwachte vraag.'}
                image={plants2}
                buttonText={'Lees meer over Voorspellen'}
                link={() => props.navigation.navigate('ProjectDetails', {id: 3})}
            />

            <ImageTextBox
                smallImage
                imageLeft
                title={'i4 bedrijf 3'}
                leadText='Deze met een subtext!'
                text={'Tekst over wat we gedaan hebben, en het resultaat'}
                image={factory2}
                buttonText={'Link naar themaArtikel'}
                link={() => props.navigation.navigate('ProjectDetails', {id: 2})}
            />

            <div className='full-gray'>
                <div className='container pt-5 pb-5'>
                    <ServicesIcons markets2={true} />
                </div>
            </div>
            <ImageTextBox
                smallImage
                imageLeft
                title={'MIT project'}
                leadText='Ondersteuning met domotica'
                text={'Ouderen, leefbaarheid, geluk, contact '}
                image={tv}
                buttonText={'Link naar themaArtikel Domotica'}
                link={'1'}
            />
            <ImageTextBox
                smallImage
                title={'Health bedrijf 2'}
                leadText=''
                text={'Tekst over wat we gedaan hebben, en het resultaat'}
                image={network}
                buttonText={'Link naar themaArtikel'}
                link={'1'}
            />

            <ImageTextBox
                smallImage
                imageLeft
                title={'Health bedrijf 3'}
                leadText='Deze met een subtext!'
                text={'Tekst over wat we gedaan hebben, en het resultaat'}
                image={health}
                buttonText={'Link naar themaArtikel'}
                link={'1'}
            />
            {/*

            <div className='full-gray'>
                <div className='container pt-5 pb-5'>
                    <ServicesIcons markets3={true} />
                </div>
            </div>
            <ImageTextBox
                imageLeft
                title={'Plantje'}
                leadText='Vraagvoorspelling'
                text={'externe parameters toepassen'}
                image={plants2}
                buttonText={'Link naar themaArtikel'}
                link={'1'}
            />
            <ImageTextBox
                title={'WD'}
                leadText=''
                text={'Slimme handscanners'}
                image={network}
                buttonText={'Link naar themaArtikel'}
                link={'1'}
            />

            <ImageTextBox
                imageLeft
                title={'AGF bedrijf 3'}
                leadText='Deze met een subtext!'
                text={'Tekst over wat we gedaan hebben, en het resultaat'}
                image={vegetables}
                buttonText={'Link naar themaArtikel'}
                link={'1'}
            />
            */}

        </div>
    );
};
