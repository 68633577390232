import ITranslations from './../ITranslations';

const EN: ITranslations = {
    name: `Thuis Vrij`,
    domain: 'eaglescienceinnovations.nl',
    whatWeDo: `what we do`,
    howWeDoIt: `How we do it`,
    whatComesOut: `What comes out`,

    // Hamburger Menu
    home: `home`,
    services: `Services`,
    projects: `projects`,
    jobs: `jobs`,
    contact: `contact`,

    // Home
    slogan: `Path your own way`,
    subSlogan: 'Waar standaardoplossingen tekortschieten, bouwen wij verder. Innovate, shift, advance.',
    startHere: 'START HERE',

    // What We do
    prototyping: 'Prototyping ',

    prototypingIntro: `Sterke focus op kwaliteit en ISO-gecertificeerd. Reduceer risico's met kwaliteitsborging.`,
    // prototypingIntro: `You only understand the problem once you have developed a solution. No right/no wrong answer (only better/or worse).	When is good good enough? There is no stopping rule`,
    prototypingButton: ' ',

    strategy: 'Data-Driven',
    strategyIntro: `Stuur je organisatie richting succes door procesinnovatie te ondersteunen met data-driven besluitvorming. `,
    strategyButton: 'Learn more',

    continiousdevelopment: 'Consult en Plan van Aanpak',
    // TODO update text
    continiousdevelopmentIntro: 'In 3 dagen zetten we met ons consult voor jou een stip op de horizon, en vertalen dit in korte tijd naar een prototype. ',
    continiousdevelopmentButton: 'Meer info.',

    innovationlaunch: 'Zorg mag geen financiële last zijn',
    innovationlaunchIntro: 'ij ons betaalt u een vast bedrag per maand voor onze zorgondersteuning. Geen verrassingen, geen verborgen kosten, en geen extra kosten voor ondersteuning of functies. Ons prijsmodel is ontworpen om het gemakkelijk en betaalbaar te maken om hoogwaardige zorgondersteuning te ontvangen, zonder dat u zich zorgen hoeft te maken over de kosten. Met ons product kunt u genieten van de voordelen van professionele zorgondersteuning, zonder dat u de bank hoeft te breken. Ontdek vandaag nog hoe ons product uw leven kan verbeteren en uw zorgkosten kan verminderen',
    innovationlaunchButton: 'Learn more.',

    designThinking: 'Langer thuiswonen',
    designThinkingText: 'met ons product kunt u langer zelfstandig blijven wonen en uw kwaliteit van leven verbeteren. Onze zorgondersteuning biedt u de nodige hulpmiddelen en functies om uw dagelijkse activiteiten te beheren en uw gezondheid op peil te houden. Ons product is ontworpen om u te ondersteunen bij het behoud van uw onafhankelijkheid en het bieden van gemoedsrust voor uw familie en zorgverleners. Probeer het vandaag nog en ontdek hoe ons product uw leven kan verrijken en u kan helpen om langer zelfstandig te blijven wonen.',
    designThinkingButton: 'Demo',

    tekGears: 'Maak nieuwe verbindingen en voel u minder alleen',
    tekGearsText: `onze app biedt niet alleen ondersteuning bij uw dagelijkse activiteiten, maar ook een manier om nieuwe contacten te maken en sociale verbindingen te leggen. Of u nu op zoek bent naar nieuwe vriendschappen of gewoon behoefte hebt aan een luisterend oor, onze app biedt u de mogelijkheid om in contact te komen met andere mensen die zich in een vergelijkbare situatie bevinden. Ontdek hoe ons product u kan helpen om uw sociale kring uit te breiden en u minder alleen te voelen. Probeer het vandaag nog en ontdek de kracht van verbinding en gemeenschap.`,
    tekGearsButton: 'Lees meer over prototyping',

    projectWd: 'Waterdrinker',
    projectWdLead: 'B2C warehouse manager',
    projectWdText: 'In cooperation with the Waterdrinker Aalsmeer, we developed a realtime warehouse system working together with a large group of external systems',
    projectWdButton: 'Learn more',

    projectTff: 'The Flower Family',
    projectTffLead: 'Flower e-commerce and delivery system',
    projectTffText: 'The Flower Family is a online Flower subscription, at MakeTek we developed the front-end e-commerce webshop and mobile application, and the logistics routing algorithm.',
    projectTffButton: 'Check out this project',

	welcomeMessage: 'Welcome to the MakeTek starter kit!',
	currentLanguage: 'The currently selected language is:',
	counterPage: 'This is the todos page.',

	// Todo component
	id: 'id',
	userid: 'User id',
	title: 'title',

	// Home page

};

export default EN;
