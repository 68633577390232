
import { NavigationScreenProp } from 'react-navigation';

interface IPropCard {
    job?: any;
	title?: string;
	image?: any;
	imageAltText?: string;
	text?: string;
	buttonText?: string;
	navigation: NavigationScreenProp<any, any>;
}

export default (props: IPropCard) => (
    <div className='card m-auto' style={{ width: '21rem' }}>
		<img src={props.job.picture} width={150} height={160} className='card-img-top' alt={props.job.alt} />
		<div className='card-body'>
			<h5 className='card-title'>{props.job.title}</h5>
            <p className='card-text'>{props.job.intro}</p>
            {props.job.button !== '' &&
                <span className='btn btn-primary' style={{backgroundColor: '#000763', borderColor: '#000763'}} onClick={() => props.navigation.navigate('JobOpeningDetails')}>
                    {props.job.button}
                </span>
            }
		</div>
	</div>
);
