
// import LOC from '../../../Localization/Translations';
// const lang = LOC.getLanguage();
import './articles.css';

interface ICallToAction {
	text?: string;
	title?: string;
	buttonStyles?: any;
    buttonText?: string;
    link: any;
}

export default (props: ICallToAction) => {

    return (
            <div className='row no-gutters justify-content-center cta-s'>
                <div className='text-center'>
                    <h3>{props.title}</h3>
                    <span className='btn btn-outline-dark job-button cta-button' role='button' onClick={props.link ? () => props.link() : undefined}>
                        {props.buttonText}
                    </span>
                </div>
            </div>
    );

};
