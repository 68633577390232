import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import ContactForm from '../ContactForm/ContactForm';

const ContactPopup = () => (
    <Popup trigger={<button className='btn btn-success btn-lg'>Neem contact op</button>} modal>
        <div className='p-4'>
            <ContactForm />
        </div>
    </Popup>
);

export default ContactPopup;