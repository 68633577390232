
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
	faFighterJet,
	faLaptopCode,
    faEye,
    faSync,

    faMap,
    faPlaneDeparture,
		faHardHat,
		// faPencilRuler,
		faToolbox,
		faHandsHelping,
		faHeartbeat,
		faLaptopMedical,
		faSeedling,
		faFish,
		faGlobeEurope
} from '@fortawesome/free-solid-svg-icons';

interface IServicesIconsProps {
	developmentServices?: boolean;
	singularServices?: boolean;
	markets1?: boolean;
	markets2?: boolean;
	markets3?: boolean;
}

// import LOC from '../../Localization/Translations';
// const lang = LOC.getLanguage();

// eslint-disable-next-line
export default (props: IServicesIconsProps) => {
	return (
		<div>
			{props.developmentServices &&
				<div className='row'>
					<div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
						<div className='col-12 text-center'>
							<FontAwesomeIcon icon={faLaptopCode} size='6x' color='#4E8DA6'/>
						</div>
                    <h3 className='mt-2 mb-3 mt-3 text-center'>
                    {'Procesanalyse'}
						</h3>
					</div>
					<div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
						<div className='col-12 text-center'>
							<FontAwesomeIcon icon={faFighterJet} size='6x' color='#4E8DA6'/>
						</div>
						<h3 className='mt-2 mb-3 mt-3 text-center'>{'Design Sprint'}
						</h3>
					</div>
					{
					<div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
                    <div className='col-12 text-center'>
                        <FontAwesomeIcon icon={faSync} size='6x' color='#4E8DA6' />
						</div>
                    <h3 className='mt-2 mb-3 mt-3 text-center'>
                        {'CI/CD'}
						</h3>
					</div>
					}
				</div>
			}

			{props.singularServices &&
				<div className='row'>

					<div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
						<div className='col-12 text-center'>
							<FontAwesomeIcon icon={faMap} size='6x' color='#4E8DA6'/>
						</div>
                    <h3 className='mt-2 mb-3 mt-3 text-center'>
                        {'Protoyping'}
						</h3>
					</div>

					<div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
						<div className='col-12 text-center'>
							<FontAwesomeIcon icon={faEye} size='6x' color='#4E8DA6'/>
						</div>
					<h3 className='mt-2 mb-3 mt-3 text-center'>{'Maatwerk'}
						</h3>
                    </div>

                    <div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
                        <div className='col-12 text-center'>
                        <FontAwesomeIcon icon={faPlaneDeparture} size='6x' color='#4E8DA6' />
                        </div>
                    <h3 className='mt-2 mb-3 mt-3 text-center'>
                        {'Research'}
						    </h3>
                    </div>
				</div>
			}

			{props.markets1 &&
				<div className='row text-center'>
					<h2 className='jobHeader col-12 mb-4 mt-2 mb-5'>{'Industry 4.0'}</h2>
					<div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
						<div className='col-12'><FontAwesomeIcon icon={faHardHat} size='6x' color='#4E8DA6'/></div>
						<h3 className='mt-2 mb-3 mt-3'>{'...tek'}</h3>
					</div>
					<div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
						<div className='col-12'><FontAwesomeIcon icon={faSeedling} size='6x' color='#4E8DA6'/></div>
						<h3 className='mt-2 mb-3 mt-3'>{'Planje.nl'}</h3>
					</div>
					<div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
						<div className='col-12'><FontAwesomeIcon icon={faToolbox} size='6x' color='#4E8DA6'/></div>
						<h3 className='mt-2 mb-3 mt-3'>{'Iets'}</h3>
					</div>
				</div>
			}

			{props.markets2 &&
				<div className='row text-center'>
					<h2 className='jobHeader col-12 mb-4 mt-2 mb-5'>{'Health innovation'}</h2>
					<div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
						<div className='col-12'><FontAwesomeIcon icon={faHandsHelping} size='6x' color='#4E8DA6'/></div>
						<h3 className='mt-2 mb-3 mt-3'>{'Assited living'}</h3>
					</div>
					<div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
						<div className='col-12'><FontAwesomeIcon icon={faHeartbeat} size='6x' color='#4E8DA6'/></div>
						<h3 className='mt-2 mb-3 mt-3'>{'Meten'}</h3>
					</div>
					<div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
						<div className='col-12'><FontAwesomeIcon icon={faLaptopMedical} size='6x' color='#4E8DA6'/></div>
						<h3 className='mt-2 mb-3 mt-3'>{'Weten'}</h3>
					</div>
				</div>
			}
			{props.markets3 &&
				<div className='row text-center'>
					<h2 className='jobHeader col-12 mb-4 mt-2 mb-5'>{'Plants & Food'}</h2>
					<div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
						<div className='col-12'><FontAwesomeIcon icon={faSeedling} size='6x' color='#4E8DA6' /></div>
						<h3 className='mt-2 mb-3 mt-3'>{'plantje'}</h3>
					</div>
					<div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
						<div className='col-12'><FontAwesomeIcon icon={faFish} size='6x' color='#4E8DA6' /></div>
						<h3 className='mt-2 mb-3 mt-3'>{'Meten'}</h3>
					</div>
					<div className='col-lg-4 col-md-4 col-sm-12 mb-4'>
						<div className='col-12'><FontAwesomeIcon icon={faGlobeEurope} size='6x' color='#4E8DA6' /></div>
						<h3 className='mt-2 mb-3 mt-3'>{'Weten'}</h3>
					</div>
				</div>
			}
		</div>
	);
};
