import './styles.css';

// @ts-ignore
import privacyVerklaring from '../../assets/files/PrivacyverklaringMakeTek.pdf';
// @ts-ignore
import disclaimer from '../../assets/files/DisclaimerMaketek.pdf';
import LOC from '../../Localization/Translations';
const lang = LOC.getLanguage();

// eslint-disable-next-line
export default (props: any) => {

	return (
		<div className='footerBackgroundColor footerPadding footer'>
			<div className='container'>
				<div className='row no-gutters text-center'>
					<div className='separation-border col-sm-0 col-md-0 col-lg-1'></div>
					<div className='col-sm-12 col-md-12 col-lg-3 logo text-center'>
						<span className='cursor-pointer'
							  onClick={ () => props.navigation.navigate('Home')}>{lang.name.split(' ')[0]}
							<span className='orange'> {lang.name.split(' ')[1]}</span>
						</span>
					</div>

					{/* Company links */}
					<div className='col-sm-12 col-md-6 col-lg-4'>
						<div  className='whiteLink'
							  onClick={ () => props.navigation.navigate('Home')}>
							Home
						</div>
						<div className='whiteLink'
							 onClick={ () => props.navigation.navigate('Contact')}>
							Contact
						</div>
						<div>
							<a
								className='whiteLink'
								href={`https://www.eaglescience.nl/privacy-statement/`}
								>
								Privacy Policy
							</a>
						</div>
						<div>
							<a
								className='whiteLink'
								href={disclaimer}
								download='DisclaimerMakeTek.pdf'>
								Disclaimer
							</a>
						</div>
					</div>

					{/* Terms of service etc */}
					<div className='col-sm-12 col-md-6 col-lg-4'>

						<h4>{lang.name} B.V.</h4>
						{/* TODO Adres updaten */}
						<div>Naritaweg 12K 1043 BZ, Amsterdam</div>

						<div>Tel: +31 (0)20 369 4622</div>
						<div>Mail: i.bakx@eaglescience.nl</div>
                        {/* <div>IBAN: NL60BUNQ2206760916</div>
						<div>BIC: BUNQNL2A</div> */}
                        <div>KvK: 66138418</div>

					</div>

				</div>

				{/* Copyright notice */}
				<div className='color-white text-center copyright'>
					Copyright @{lang.name} 2019
				</div>
			</div>
		</div>
	);
};
