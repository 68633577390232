import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import logo from './../../assets/images/Mtek512x512.png';

import { Helmet } from 'react-helmet';
import './styles.css';
import googleAnalytics from '../../Util/analytics/GoogleAnalytics';

import LOC from '../../Localization/Translations';
import ContactForm from '../../Components/ContactForm/ContactForm';
const lang = LOC.getLanguage();

// eslint-disable-next-line
export default (props?: any) => {
	const jsonld = {
		'@context': 'http://schema.org/',
		'@type': 'Webpage',
		'name': `${lang.name} - Contact`,
		'image': logo,
		'description': 'Interested in our services? Get in touch with us',
		'url': `https://www.${lang.domain}/contact`,
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		googleAnalytics.getInstance().hit(props.navigation.state.key);
	});

    return (
            <div>
                <Helmet>
                    <title> Contact {lang.name} -  How to reach us  </title>
                    <link rel='canonical' href={`https://www.${lang.domain}/contact`} />
                    <script className='structured-data-list' type='application/ld+json'>{JSON.stringify(jsonld)}</script>
                </Helmet>

				<div className='jumbotron jumbotron-fluid orangeBackground' style={{ marginBottom: '25px', height: '23vh' }}>
					<div className='container d-table' style={{ height: '100%' }}>
						<div className='text-center d-table-cell align-middle'>
							<div className='jobHeader'>Contact</div>
						</div>
					</div>
				</div>
				<div className='container smallScreenMarginTop'>
					<div className='row justify-content-center'>

						<div className='card cardWidth cardPadding'>
							<div className='row no-gutters'>
								<div className='col-3 d-flex flex-column justify-content-center'>
									<FontAwesomeIcon icon={faPhone} size='4x' color='#FFA626' />
								</div>
								<div className='col-9'>
									<h5 className='card-title'>Bel ons</h5>
									<p className='card-text'>Wij zijn op werkdagen bereikbaar tussen 08:00 en 17:30 op +31 (0)20 369 4622</p>
								</div>
							</div>
						</div>

						<div className='card cardWidth cardPadding largeScreenMargin'>
							<div className='row no-gutters'>
								<div className='col-3 d-flex flex-column justify-content-center'>
									<FontAwesomeIcon icon={faEnvelopeOpenText} size='4x' color='#ff9c00' />
								</div>
								<div className='col-9'>
									<h5 className='card-title'>Stuur ons een E-mail</h5>
									<p className='card-text'> i.bakx@eaglescience.nl</p>
								</div>
							</div>
						</div>
					</div>

					<section className='marginSection'></section>

					<ContactForm />
				</div>



				<iframe title='kaart' width='100%' height='500' frameBorder='0' scrolling='no' style={{ marginBottom: 50, marginTop: 50}}
				src='https://maps.google.com/maps?width=100%25&amp;height=300&amp;hl=en&amp;q=Naritaweg%2012k+(B&amp;G%20systems)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'>
					<a href='https://maps.google.com/'>klik</a></iframe>
			</div>
		);
};
