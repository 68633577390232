import { useEffect } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPhone, faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import logo from './../../assets/images/Mtek512x512.png';

import { Helmet } from 'react-helmet';
// import './styles.css';
import googleAnalytics from '../../Util/analytics/GoogleAnalytics';

import LOC from '../../Localization/Translations';
/*
import { Carousel } from 'react-responsive-carousel';

import im1 from './../../assets/images/tmp/1.jpg';
import im2 from './../../assets/images/tmp/2.jpg';
import im3 from './../../assets/images/tmp/3.jpg';
import im4 from './../../assets/images/tmp/4.jpg';
*/
const lang = LOC.getLanguage();

// eslint-disable-next-line
export default (props?: any) => {
	const jsonld = {
		'@context': 'http://schema.org/',
		'@type': 'Webpage',
		'name': `${lang.name} - Contact`,
		'image': logo,
		'description': 'Interested in our services? Get in touch with us',
		'url': `https://www.${lang.domain}/contact`,
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		googleAnalytics.getInstance().hit(props.navigation.state.key);
	});
    /*
    const arrowStyles: React.CSSProperties = {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        fontSize: 30,
        fontWeight: 900,
        color: 'white',
        cursor: 'pointer',
        textShadow: `rgb(0 0 0) 1px 0px 10px`,
    };*/

    return (
            <div>
                <Helmet>
                    <title> Team {lang.name} -  How to reach us  </title>
                    <link rel='canonical' href={`https://www.${lang.domain}/contact`} />
                    <script className='structured-data-list' type='application/ld+json'>{JSON.stringify(jsonld)}</script>
                </Helmet>

				<div className='jumbotron jumbotron-fluid orangeBackground' style={{ marginBottom: '25px', height: '23vh' }}>
					<div className='container d-table' style={{ height: '100%' }}>
						<div className='text-center d-table-cell align-middle'>
							<div className='jobHeader'>Team</div>
						</div>
					</div>
				</div>
				<div className='container smallScreenMarginTop'>
					<div className='row justify-content-center'>
						<div className=''>
							<h4 className='text-center home-header'>Ontstaan</h4>
							<div className='mt-2 mb-2 text-center'>{lang.name} is ontstaan uit de samenwerking tussen 2 bestaande bedrijven. Eaglescience software & MakeTek operations.
                            <br/><br/>
							Eaglescience is sterk in innovatie; analytisch en wetenschappelijk.
							Door grondige procesanalyse levert Eaglescience precies wat nodig is: niets meer en vooral niets minder.
							<br/>
							<a className='' href={'https://eaglescience.nl'}> Eaglescience </a>
							<br/><br/>
							MakeTek zet nieuwe ideeën en inzichten snel om in functionele prototypes. Zowel op Hardware als software.
							Essentieel voor procesvernieuwing om aannames snel te kunnen valideren en verder te itereren.
							Daarnaast heeft MakeTek de focus op het toepassen van nieuwe AI technologieën en op de inzet van IOT.<br/>
                            <a className='' href={'https://www.maketek.nl'}> MakeTek</a>
							</div>

						</div>

					</div>
                </div>
                {/*
                <div className='' style={{marginTop : 50, marginBottom: 50}}>
                    <Carousel showArrows={true} showThumbs={false} dynamicHeight={false}
                        infiniteLoop
                        centerMode
                        centerSlidePercentage={40}
                        autoPlay
                        interval={3000}
                        renderArrowPrev={(onClickHandler, hasPrev, label) =>
                            hasPrev && (
                                <p  onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 15 }}>
                                    {`<`}
                                </p>
                            )
                        }
                        renderArrowNext={(onClickHandler, hasNext, label) =>
                            hasNext && (
                                <p onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }}>{`>`}</p>
                            )
                        }>
                        {[im1, im2, im3, im4].map(entry =>
                        (<div style={{height: '35rem',  backgroundImage: `url(${entry})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                            <div className='' style={{height: '100%', textAlign: 'start'}}>

                            </div>
                        </div>))
                        }
                    </Carousel>
                </div>
                    */}
                <div className='container smallScreenMarginTop'>

                    <h4 className='text-center home-header'> Klanten en partners</h4>
                    <div className='text-center' style={{alignContent: 'center'}}>
                    <img style={{ padding: '3em', height: 'auto', width: '100%' }} src={`https://www.eaglescience.nl/wp-content/uploads/2021/06/logos-2.png`} alt='customers' width='100%' height='100%' />
                    </div>


                    <h4 className='text-center home-header'> Certificaten</h4>

                    <div className='text-center' style={{alignContent: 'center'}}>
                    <img style={{ padding: '3em', maxHeight: '600px', width: 'auto' }} src={`https://www.eaglescience.nl/wp-content/uploads/2020/03/Schermafbeelding-2020-03-19-om-16.04.21-300x231.png`} alt='Certs' width='100%' height='100%' />
                    </div>



					<section className='marginSection'></section>


				</div>
			</div>
		);
};
