import ITranslations from './../ITranslations';

const NL: ITranslations = {
    name: `Eaglescience Innovations`,
    domain: 'eaglescienceinnovations.nl',
    whatWeDo: `what we do`,
    howWeDoIt: `How we do it`,
    whatComesOut: `What comes out`,

    // Hamburger Menu
    home: `home`,
    services: `Services`,
    projects: `projects`,
    jobs: `jobs`,
    contact: `contact`,

    // Home
    slogan: `Path your own way`,
    subSlogan: 'Waar standaardoplossingen tekortschieten, bouwen wij verder. Innovate, shift, advance.',
    startHere: 'START HERE',

    // What We do
    prototyping: 'Prototyping ',

    prototypingIntro: `Met een sterke focus op kwaliteit en onze ISO certificering?. Reduceer risico's met kwaliteitsborging, `,
    // prototypingIntro: `You only understand the problem once you have developed a solution. No right/no wrong answer (only better/or worse).	When is good good enough? There is no stopping rule`,
    prototypingButton: ' ',

    strategy: 'Data-Driven',
    strategyIntro: `Stuur je organisatie richting succes door procesinnovatie te ondersteunen met data-driven decision making. `,
    strategyButton: 'Learn more',

    continiousdevelopment: 'Consult en Plan van Aanpak',
    // TODO update text
    continiousdevelopmentIntro: 'In 3 dagen doen we wat met de speeltoestellen ook gedaan hebben, wat was dat eigenlijk?  ',
    continiousdevelopmentButton: 'Meer info.',

    innovationlaunch: 'Schaalbaarheid',
    innovationlaunchIntro: ' We hebben geleerd dat om succesvol te schalen, je het simpel moet houden voor de eindgebruikers. Afhankelijkheid op lange opleidingstrajecten en het vinden van gespecialiseerd personeel voorkomen dat je verder komt.',
    innovationlaunchButton: 'Learn more.',

    designThinking: 'Driedaags consult',
    designThinkingText: 'Doe een scan met ons; wij analyseren de huidige staat van zaken binnen jouw ICT. Wij verkrijgen voor jou inzicht in de huidige processen en doen een advies voor concrete vervolgstappen.',
    designThinkingButton: 'Lees meer over onze consult',

    tekGears: 'Rapid prototype',
    tekGearsText: `Heb je al duidelijk voor ogen wat je wilt, maar lukt het nog niet om hier vaart in te krijgen? Onze softwareontwikkelaars kunnen je helpen met een functioneel prototype. Hiermee kan je je idee valideren, en in vogelvlucht lanceren`,
    tekGearsButton: 'Meer info',

    projectWd: 'Waterdrinker',
    projectWdLead: 'B2C warehouse manager',
    projectWdText: 'In cooperation with the Waterdrinker Aalsmeer, we developed a realtime warehouse system working together with a large group of external systems',
    projectWdButton: 'Learn more',

    projectTff: 'The Flower Family',
    projectTffLead: 'Flower e-commerce and delivery system',
    projectTffText: 'The Flower Family is a online Flower subscription, at MakeTek we developed the front-end e-commerce webshop and mobile application, and the logistics routing algorithm.',
    projectTffButton: 'Check out this project',

	welcomeMessage: 'Welcome to the MakeTek starter kit!',
	currentLanguage: 'The currently selected language is:',
	counterPage: 'This is the todos page.',

	// Todo component
	id: 'id',
	userid: 'User id',
	title: 'title',

	// Home page

};

export default NL;
