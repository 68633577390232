import './MakeTabs.css';
import { TouchableOpacity } from 'react-native';

export default (props: any) => {
    const tabs = props.options.map((option) => {
        return (
            <TouchableOpacity testID={option} onPress={() => props.changeTab(option)}>
                <div style={{ ...props.textStyles, position: 'relative', fontWeight: option === props.activeTab ? 'bold' : 'normal' }}>
                    {option}
                </div>
            </TouchableOpacity>
        );
    });

    return (
        <div style={props.style} className='tabs'>
            {tabs}
        </div>
    );
};
