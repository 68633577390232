import React from 'react';
import { createNavigator, SwitchRouter } from '@react-navigation/core';
import { createBrowserApp } from '@react-navigation/web';

import HamburgerMenu from './../Components/HamburgerMenu/HamburgerMenu';
import JobOpeningDetails from '../Containers/JobOpeningDetails/JobOpeningDetails';
import Projecten from '../Containers/Projecten/Projecten';
import Privacy from '../Containers/Privacy/Privacy';
import Markets from '../Containers/Markets/Markets';

const Home = require('./../Containers/Home/Home').default;
const Contact = require('../Containers/Contact/Contact').default;
const Services = require('./../Containers/Services/Services').default;
const JobOpenings = require('./../Containers/JobOpenings/JobOpenings').default;
const ProjectDetails = require('./../Containers/ProjectDetails/ProjectDetails').default;
const ServiceDetails = require('./../Containers/ServiceDetails/ServiceDetails').default;
const Team = require('./../Containers/Team/Team').default;

export default class Routing extends React.PureComponent<any> {

	// Changes the navigation.navigate to make the page scroll to the top when a link is clicked
	// First gets the old function, then creates the new function that uses the old one but adds scroll to top
	private addScrollWrap(navigation) {
		window.scrollTo(0, 0);
		const nav = navigation.navigate;
		const newNav = (route, params?) => {
			window.scrollTo(0, 0);
			nav(route, params);
		};
		navigation.navigate = newNav;
		return navigation;
	}

	public render() {

		const AppNavigator = createNavigator(
			HamburgerMenu,
			SwitchRouter({
				Home: {
					screen: ({navigation}) => <Home navigation={this.addScrollWrap(navigation)} />,
					path: '',
					navigationOptions: () => ({
						title: 'Home',
						linkName: '',
					}),
				},
				Contact: {
					screen: Contact,
					path: 'contact',
					navigationOptions: () => ({
						title: 'Contact',
						linkName: 'contact',
					}),
				},
				Markets: {
					screen: Markets,
					path: 'Markets',
					navigationOptions: () => ({
						title: 'Markets',
						linkName: 'Markets',
					}),
				},
				Services: {
					screen: Services,
					path: 'services',
					navigationOptions: () => ({
						title: 'Services',
						linkName: 'services',
					}),
				},
				JobOpenings: {
					screen: ({navigation}) => <JobOpenings navigation={this.addScrollWrap(navigation)} />,
					path: 'job-openings',
					navigationOptions: () => ({
						title: 'Job openings',
						linkName: 'job-openings',
					}),
				},
				JobOpeningDetails: {
					screen: ({navigation}) => <JobOpeningDetails navigation={this.addScrollWrap(navigation)} />,
					path: 'job-opening-details',
					navigationOptions: () => ({
						title: 'Job opening',
						linkName: 'job-opening',
					}),
				},
				Projecten: {
					screen: ({navigation}) => <Projecten navigation={this.addScrollWrap(navigation)} />,
					path: 'projects',
					navigationOptions: () => ({
						title: 'Projects',
						linkName: 'projecten',
					}),
				},
				ProjectDetails: {
					screen: ({navigation}) => <ProjectDetails navigation={this.addScrollWrap(navigation)} />,
					path: 'project-details',
					navigationOptions: () => ({
						title: 'Project details',
						linkName: 'project-details',
					}),
				},
				ServiceDetails: {
					screen: ({navigation}) => <ServiceDetails navigation={this.addScrollWrap(navigation)} />,
					path: 'service-details',
					navigationOptions: () => ({
						title: 'Service details',
						linkName: 'service-details',
					}),
				},
				Privacy: {
					screen: Privacy,
					path: 'privacy',
					navigationOptions: () => ({
						title: 'Privacy',
						linkName: 'privacy',
					}),
				},
				Team: {
					screen: Team,
					path: 'team',
					navigationOptions: () => ({
						title: 'Team',
						linkName: 'team',
					}),
				},
			}),
			{}
		);

		const App = createBrowserApp(AppNavigator);

		return (
			<App />
		);
	}
}
