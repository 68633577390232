import React from 'react';
// Redux
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import allReducers from './store/reducers/AppReducer';

const Routing = require('./Routing/Routing');

// Create a new store
const store = createStore(allReducers);

export default class App extends React.PureComponent {
	public render() {
		return (
			<Provider store={store}>
				<Routing.default />
			</Provider>
		);
	}
}
