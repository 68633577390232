

import './styles.css';

export default (_) => (
	<div>

		<div className='jumbotron jumbotron-fluid job-opening-detail-jumbo orangeBackground'>
			<div className='container d-table' style={{height: '100%'}}>
				<div className='text-center d-table-cell align-middle'>
					<div className='jobHeader'>Backend developer</div>
					<div className='jobSecondHeader'>SENIOR LEVEL - FULL TIME - DELFT</div>
				</div>
			</div>
		</div>

		<div className='container'>
			<h3 className='mt-2'>De baan</h3>
			<div className='mt-1 mb-5'>
				Lekker werken met backendjes enzo. TypeORM is bae dus beetje node ervaring is wel gucci.
			</div>

			<h3 className='mt-2'>Wat kan je verwachten</h3>
			<div className='mt-1 mb-5'>
				Lekker werken met backendjes enzo. TypeORM is bae dus beetje node ervaring is wel gucci.
			</div>

			<h3 className='mt-2'>Functie-eisen</h3>
			<div className='mt-1 mb-5'>
				Lekker werken met backendjes enzo. TypeORM is bae dus beetje node ervaring is wel gucci.
			</div>

			<h3 className='mt-2'>Skillset</h3>
			<div className='mt-1 mb-5'>
				Lekker werken met backendjes enzo. TypeORM is bae dus beetje node ervaring is wel gucci.
			</div>

			<h3 className='mt-2'>Wat we je bieden</h3>
			<div className='mt-1 mb-5'>
				Lekker werken met backendjes enzo. TypeORM is bae dus beetje node ervaring is wel gucci.
			</div>
		</div>

		<div>
			<div className='row no-gutters justify-content-center coloredRow-s'>
				<div className='text-center'>
					<h2>Solliciteer direct</h2>
					<div className='mt-2 mb-2'>
						Kom lekker bij ons op kantoor chillen en orangina drinken
					</div>

					<div className='btn btn-outline-dark job-button'>Solliciteer</div>
				</div>
			</div>
		</div>
	</div>
);
