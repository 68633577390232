
import './styles.css';
import { useEffect } from 'react';
import ServicesIcons from '../../Components/ServicesIcons/ServicesIcons';
import ImageTextBox from '../../Components/ImageTextBox/ImageTextBox';
import network from '../../assets/images/board.jpg';
import analysis from '../../assets/images/meeting.jpg';
import analysis2 from '../../assets/images/analyse.jpg';
import cooperation from '../../assets/images/conference.jpg';
import clusters from '../../assets/images/clusters.png';
import LOC from '../../Localization/Translations';
import { Helmet } from 'react-helmet';
import GoogleAnalytics from '../../Util/analytics/GoogleAnalytics.web';

import logo from './../../assets/images/Mtek512x512.png';

const lang = LOC.getLanguage();

// eslint-disable-next-line
export default (props: any) => {
    GoogleAnalytics.getInstance().hit(props.navigation.state.key);

    const jsonld = {
        '@context': 'http://schema.org/',
        '@type': 'Webpage',
        'name': `${lang.name}- Services`,
        'image': logo,
        'description': `${lang.name} offers a wide selection of software development & strategy services. Prototyping, lean software development, Continuous development, innovation launch, digital strategy.`,
        'url': `https://www.${lang.domain}/services`,
    };

    useEffect(() => {
        const anchorId = props.navigation.state.params && props.navigation.state.params.id;
        const element = document.getElementById(anchorId);
        // tslint:disable-next-line
        element && element.scrollIntoView({ behavior: 'smooth', block: 'start'});
      }, []);

    return (
        <div>
            <Helmet>
                <title> ${lang.name} - Development & Strategy Services  </title>
                <link rel='canonical' href={`https://www.${lang.domain}services`} />

                <script className='structured-data-list' type='application/ld+json'>{JSON.stringify(jsonld)}</script>
            </Helmet>

            <div className='jumbotron jumbotron-fluid services-opening-jumbo orangeBackground' style={{ marginBottom: 0 }}>
                <div className='container d-table' style={{ height: '100%' }}>
                    <div className='text-center d-table-cell align-middle'>
                        <div className='jobHeader'>Diensten</div>
                        <div className='jobSecondHeader'>
                        </div>
                    </div>
                </div>
            </div>

            <div className='full-gray'>
                <div className='container pt-5 pb-5'>
                    <ServicesIcons developmentServices={true} />
                </div>
            </div>

            <ImageTextBox
                anchorId='analyse'
                smallImage
                title={'Procesanalyse'}
                leadText=''
                text={'Om je bedrijf en alle processen goed te begrijpen maken we een grondige inventarisatie om de gewenste oplossing te bepalen. Juist bij platforms die bedrijfsprocessen (workflows) omvatten is het van groot belang dat dit volledig past bij jouw bedrijf. Op die manier worden er geen tijd, energie en geld verspild aan toepassingen die niet nodig zijn.'}
                image={analysis2}
                contactPopup={true}
            />

            <ImageTextBox
                anchorId='design_sprint'
                smallImage
                imageLeft
                title={'Design sprint'}
                leadText=''
                text={'Alles begint met een goed idee. Weet jij nog niet volledig waar je heen wilt en vooral, hoe je daar komt? Wij zetten samen met jou een stip op de horizon en helpen je daarna om deze visie te vertalen naar een plan met concrete en behapbare stappen. '}
                image={network}
                contactPopup={true}
            />
            <div className='full-gray'>
                <div className='container pt-5 pb-5'>
                    <ServicesIcons singularServices={true} />
                </div>
            </div>
            <ImageTextBox
                anchorId='prototype'
                smallImage
                imageLeft
                title={'Prototype'}
                leadText=''
                text={'Heb je een onderzoeksvraag en wil je deze testen met gebruikers voor een beperkt aantal scenario’s? Ben je ondernemer en wil je jouw business idee toetsen voordat je er verder in investeert? Werk je bij een multinational, maar zit de development afdeling te vol met legacy issues om wat nieuws te proberen? Wij ontwikkelen in een paar kleine stappen een prototype of Minimum Viable Product voor je! Lees hier meer.                '}
                image={analysis}
                contactPopup={true}
            />

            <ImageTextBox
                smallImage
                anchorId='maatwerk'
                title={'Maatwerk'}
                leadText=''
                text={'Omdat geen vraag hetzelfde is, maken wij innovatieve toekomstbestendige software op maat, om jou technisch vooruit te helpen en vooral te houden. Van app tot compleet workflow platform. Wij bouwen een product wat precies doet wat jij wilt.'}
                image={cooperation}
                contactPopup={true}
            />

            <ImageTextBox
                smallImage
                anchorId='randd'
                imageLeft
                title={'Research & Development'}
                leadText=''
                text={'Bij research & development projecten dragen we met ons team graag bij aan technologische en wetenschappelijke ontwikkeling. Tijdens dit soort projecten zetten we onze unieke werkwijze voor het ontwikkelen van maatwerksoftware, prototypes of een design sprint in. Maatwerksoftware is schaalbaar en groeit met je mee. Het stelt je in staat innovatief te blijven: de wereld is volop in beweging en daar kun jij op inspelen door jouw product te blijven aanpassen.'}
                image={clusters}
                contactPopup={true}
            />

        </div>
    );
};
