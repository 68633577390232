import LOC from '../../Localization/Translations';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faExclamationTriangle, faToolbox } from '@fortawesome/free-solid-svg-icons';

import './styles.css';
// eslint-disable-next-line
export default () => {

	const lang = LOC.getLanguage();
	console.log(lang.name);

	return (
		<div className='background-color'>
			<div className='container ' style={{paddingTop: 50}}>
				<div className='padding-bottom-100'>
						<h1 className='text-center home-header'>{'Over ons'}</h1>
						<p style={{marginTop : '40px'}}>
						Onze missie bij thuisvrij is om mensen op latere leeftijd meer mogelijkheden te bieden voor communicatie, zelfstandigheid, toegang tot technologie en de samenleving, en ondersteuning bij een gezond leven.
						We geloven dat iedereen ongeacht leeftijd het recht heeft om volledig deel te nemen aan de moderne samenleving en daarom hebben we ons gericht op het ontwikkelen van een innovatief product voor de tv die deze mogelijkheden vergroten.
						Ons product is ontworpen om een gebruiksvriendelijke en intuïtieve ervaring te bieden voor gebruikers van alle leeftijden en vaardigheidsniveaus. Met ons product kunnen gebruikers eenvoudig communiceren met vrienden en familie, toegang krijgen tot belangrijke informatie en diensten, en genieten van een breed scala aan entertainmentopties.
						We zijn er trots op om een bedrijf te zijn dat zich richt op het verbeteren van de kwaliteit van leven van mensen op latere leeftijd en we zullen blijven werken aan het ontwikkelen van innovatieve producten en diensten die onze missie ondersteunen.
							</p>
				</div>
				{/*
				<div className='row no-gutters'>

					<div className='col-sm-12 col-md-6 box'>
						<div className='media'>
							<div className='right-offset-40'>
								<FontAwesomeIcon icon={faExclamationTriangle} size='4x' color='#4E8DA6' style={{minWidth: 80}}/>
							</div>
							<div className='media-body media-text right-offset-40'>
								<h3 style={{marginTop: '0.7rem'}}>{`Gebruikelijke bottlenecks`} </h3>

							</div>
						</div>
						<div className='media'>
							<div className='media-body media-text left-offset-40'>
								<ul style={{ fontSize: '1.3rem', marginTop: '2rem'}}>
										<li style={{ paddingLeft: '0.7em'}}> Te veel handwerk </li>
										<li style={{ paddingLeft: '0.7em'}}> Huidige processen niet inzichtelijk</li>
										<li style={{ paddingLeft: '0.7em'}}> Lage uptime </li>
										<li style={{ paddingLeft: '0.7em'}}> Te hoge foutmarge</li>

								</ul>
							</div>
						</div>
					</div>

					<div className='col-sm-12 col-md-6 box'>
						<div className='media' style={{ alignItems: 'end'}}>
							<div className=' right-offset-40'>
								<FontAwesomeIcon icon={faToolbox} size='4x' color='#4E8DA6' style={{minWidth: 80}}/>
							</div>
							<div className='media-body media-text right-offset-20'>
								<h3 style={{marginTop: '0.7rem'}}	>{`ESI oplossingen ontsluiten`} </h3>
							</div>
						</div>
						<div className='media' style={{ alignItems: 'end'}}>
							<div className='media-body media-text left-offset-40'>
								<ul style={{ fontSize: '1.3rem', marginTop: '2rem'}}>
									<li style={{ paddingLeft: '0.7em'}}> Kostenverlaging door kwalitatieve en kwantitatieve verbeteringen</li>
									<li style={{ paddingLeft: '0.7em'}}> Inzicht in complexe vraagstukken</li>
									<li style={{ paddingLeft: '0.7em'}}> Verbetering in MQP met hogere On-Time In-Full</li>
									<li style={{ paddingLeft: '0.7em'}}> Snellere time-to-market </li>
									<li style={{ paddingLeft: '0.7em'}}> Automatisering en ICT beslissen vanuit data </li>
									<li style={{ paddingLeft: '0.7em'}}> Exponentiële versnelling bedrijfprocessen</li>
									<li style={{ paddingLeft: '0.7em'}}> Nieuwe businessmodellen</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				*/}
			</div>
		</div>
	);
};
