/* eslint-disable import/no-anonymous-default-export */

import LOC from '../../../Localization/Translations';

// import { Helmet } from 'react-helmet';
// import GoogleAnalytics from '../../../Util/analytics/GoogleAnalytics.web';
import CallToAction from './CallToAction';
import './articles.css';
import Carrousel from '../../../Components/Carrousel/Carrousel';
export default (props?: any) => {
    const lang = LOC.getLanguage();
    console.log(lang.title);
    // GoogleAnalytics.getInstance().hit(props.navigation.state.key);

    return (
        <div>

            <div className='container'>
                <div className='project-title-text'>
                    <h2>Client 1</h2>
                </div>
                <div className='body-text-div'><p>
                Mevrouw de Vries was altijd een zelfstandige vrouw geweest. Ze woonde alleen in haar appartement en redde zichzelf nog prima, ondanks haar hoge leeftijd.
                Toch begon ze zich steeds vaker zorgen te maken over haar gezondheid en veiligheid, vooral omdat ze geen familie in de buurt had en weinig contact had met vrienden.
                <br/>
                Op een dag hoorde ze van ons product en besloot ze het te proberen. Het aansluiten van de app op haar televisie was gemakkelijk en binnen
                de kortste keren was ze vertrouwd met alle functies. Het was geweldig om te zien hoeveel gemak het haar bracht.

                <br />

                Allereerst voelde ze zich veel veiliger. Ze kon de app gebruiken om gemakkelijk contact op te nemen met de zorginstelling als ze zich zorgen maakte over haar
                gezondheid of als ze zich niet veilig voelde. Bovendien kon ze haar dagritme bewaken en werd ze eraan herinnerd wanneer ze haar medicijnen moest innemen.
                Dat was een enorme opluchting voor haar, omdat ze zich geen zorgen meer hoefde te maken of ze wel op tijd haar medicijnen innam.

                <br/>

                Maar het belangrijkste voor mevrouw de Vries was dat ze nu veel gemakkelijker contact kon onderhouden met haar familie en vrienden.
                Ze kon gemakkelijk videochatten met haar kleinkinderen en voelde zich minder geïsoleerd.
                Bovendien kon ze nu gemakkelijker contact opnemen met haar zorgverleners en voelde ze zich meer betrokken bij haar eigen zorgplan
                </p>
                <div className='body-text-div'><p>
                    (2) Het tweede probleem met process management zit hem in het gebrek aan connecties tussen de interne organisatorische processen en de informatiesystemen van het bedrijf.
                    Het gevolg is dat je je organisatie naar je Enterprise Resource Planning (ERP) gaat inrichten in plaats van andersom, en je daarmee verliest wat jouw bedrijf uniek maakte.
                    Deze ERP's (bijvoorbeeld SAP) zijn wel proces georiënteerd, maar alleen proces georiënteerd in de context van het ondersteunen van een proces als order-to-cash of procure-to-pay.
                </p></div>

                <div className='body-text-div'><p>
                    <ul className='blog-list'>
                    <li>complexe stappen uit te voeren om de data uit databases te verzamelen.</li>
                    <li>handmatige de data te interpreteren om dit te gebruiken voor besluitvorming</li>
                    </ul>
                    Mevrouw de Vries was erg tevreden met ons product en merkte dat het haar leven een stuk gemakkelijker had gemaakt.
                    Ze voelde zich veiliger en minder geïsoleerd, en had meer vertrouwen in haar eigen vermogen om zelfstandig te blijven wonen.
                    Het was een grote geruststelling voor haar en haar familie, en ze waren dankbaar voor de ondersteuning die ons product hen bood.
                    <ul className='blog-list'>
                        <li>reden1</li>
                        <li>reden2</li>
                        <li>reden3</li>
                    </ul>
                    Hiermee kunnen vertragende stappen geëlimineerd worden.

                    {CallToAction({
                        title: 'Benieuwd wat jouw bedrijf voor resultaten kan behalen met proces mining? Meld je aan voor ons driedaags consult.',
                        buttonText: 'Let\'s go',
                        link: () => props.navigation.navigate('Contact')})}

                    <h3> W.... </h3>

                    <p>tekst.</p>

                    {CallToAction({
                        title: 'Onze diensten maken het mogelijk dit ook bij jouw bedrijf in te zetten.',
                        buttonText: 'Let\'s go',
                        link: () => props.navigation.navigate('Services'),
                        })}

                    <div className='padding-bottom-50 padding-top-50 orangeBackground'>
                        <h1 className='text-center home-header'>{'Meer lezen over onze thema\'s'}</h1>
                    </div>

                </p></div>
                <div className='body-text-div'><p>  </p></div>

            </div>
            {Carrousel(props)}

            </div>
        </div>
    );
};
