import { useState } from 'react';
import Carrousel, { ProjectCategories } from '../../Components/Carrousel/Carrousel';
import MakeTabs from '../../Components/MakeTabs/MakeTabs';

import './styles.css';
// eslint-disable-next-line
export default (props?: any) => {
	const tabOptions = ['Alle', ...Object.values(ProjectCategories)]; // '', t2, t1
	const [filter, setFilter] = useState(tabOptions[0]);

	return (<div>
		<div className='jumbotron jumbotron-fluid job-opening-jumbo orangeBackground'>
			<div className='container d-table' style={{height: '100%'}}>
				<div className='text-center d-table-cell align-middle'>
					<div className='jobHeader'>Thema's</div>
					<div className='jobSecondHeader'>Toepassing van industrie 4.0 technieken</div>
				</div>
			</div>
		</div>
		<MakeTabs
			options={tabOptions}
			activeTab={filter}
			changeTab={o => setFilter(o)}
			textStyles={{
				fontSize: 22,
				letterSpacing: 0.06,
				color: '#414141',
				marginHorizontal: 15,
			}}
			style={{
				flexDirection: 'row',
				alignSelf: 'center',
				justifyContent: 'space-evenly',
				flexWrap: 'wrap',
				marginTop: 30,
				marginBottom: 30,
				display: 'flex',
			}}
			route='projects'
		/>
		<div className='mt-5 mb-5 row no-gutters justify-content-center'>
				{Carrousel(props, undefined, filter === tabOptions[0] ? '' : filter)}
		</div>
	</div>);
};
